<template>
  <el-dialog
      title="新增附属设备"
      :close-on-click-modal="true"
      :visible.sync="addSubEquipmentDialog"
      v-if="addSubEquipmentDialog"
      @close="closeDialog"
      width="50%"
      append-to-body>

    <el-steps :space="200" :active="activeIndex-0" finish-status="success" align-center>
      <el-step title="所属设备"></el-step>
      <el-step title="填写附属设备信息"></el-step>
      <el-step title="确认"></el-step>
    </el-steps>

    <el-form :label-position="'top'" label-width="100%" :rules="rules" ref="addForm" :model="addForm">
      <el-tabs :tab-position="'left'" v-model="activeIndex">
        <el-tab-pane label="所属设备" name="0">
          <el-form-item label="设备名称" prop="parentList">
<!--            <el-cascader-->
<!--                v-model="addForm.parentList"-->
<!--                :options="options"-->
<!--                separator=" | "-->
<!--                style="width: 50%;"-->
<!--                :props="props"-->
<!--                placeholder="请选择设备"-->
<!--                clearable-->
<!--                ref="cascade"-->
<!--            ></el-cascader>-->
            <el-input v-model="global.coal_name + ' | ' + global.system_name + ' | ' + global.subSystem_name + ' | ' + global.equipment_name" readonly="true" style="width: 500px"></el-input>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="填写附属设备信息" name="1">
          <el-form-item label="附属设备名称" prop="subEquipmentName">
            <el-input style="width: 45%;margin-bottom: 5px" v-model="addForm.subEquipmentName" placeholder="请输入附属设备名称"></el-input>
          </el-form-item>
          <!--          <el-form-item label="使用状态" prop="enabled">-->
          <!--            <el-switch v-model="addForm.enabled" active-text="可用" inactive-text="不可用"></el-switch>-->
          <!--          </el-form-item>-->
          <el-form-item label="描述" prop="remark">
            <el-input style="width: 45%;margin-bottom: 5px" v-model="addForm.remark" placeholder="添加附属设备描述" type="textarea"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="确认" name="2">
          <el-form-item label="当前操作员" prop="currOperatorName" style="margin-bottom: 10px">
            <el-input style="width: 20%" class="small-input" v-model="addForm.currOperatorName" placeholder="管理员user"
                      disabled></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">立即创建</el-button>
            <!--            <el-button @click="resetForm">重置</el-button>-->
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </el-dialog>
</template>

<script>
import {isSpecialCharacter} from "@/utils/validate";

let id = 0;

export default {
  name: "addSubEquipment",
  data() {
    var validateIsSpecialcharacter = (rule,input,callback) =>{
      if(isSpecialCharacter(input)) {
        callback(new Error('请不要输入除了“#”的特殊字符'))
      }
      else{
        callback();
      }
    }
    let that = this;
    return {
      addSubEquipmentDialog: false,
      activeIndex: 0,
      options: [],
      value: '',
      addForm: {
        subEquipmentName: '',
        enabled: '不可用',
        remark: '',
      },
      rules: {
        // parentList: [
        //   {required: true, message: '请选择设备', trigger: 'blur'}
        // ],
        subEquipmentName: [
          {required: true, message: '请输入附属设备名称', trigger: 'blur'},
          {validator:validateIsSpecialcharacter,trigger: 'blur'}
        ],
        // enabled: [
        //   {required: true, message: ' 请选择使用状态', trigger: 'blur'}
        // ],
        remark: [
          {required: true, message: '请输入附属设备描述', trigger: 'blur'}
        ]
      },
      props: {
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(level, node);
          if (level == 0) {
            that.getAllCoal((list1) => {
              let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            console.log(value)
            that.getSystem(value, (list2) => {
              console.log(list2)
              if (list2[0] == null) {
                let arr = list2.map((e) => ({ value: 0, label: 0}));
                resolve(arr);
              } else {
                let arr = list2.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              }
            })
          }
          if (level == 2) {
            let { value } = node;
            console.log(value)
            that.getSubSystem(value, (list3) => {
              console.log(list3)
              if (list3[0] == null) {
                let arr = list3.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              } else {
                let arr = list3.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              }

            })
          }
          if (level == 3) {
            let { value } = node;
            console.log(value)
            that.getEquipment(value, (list4) => {
              console.log(list4)
              if (list4[0] == null) {
                let arr = list4.map((e) => ({ value: e.id, label: e.name, leaf: true}));
                resolve(arr);
              } else {
                let arr = list4.map((e) => ({ value: e.id, label: e.name, leaf: true}));
                resolve(arr);
              }

            })
          }
          // if (level == 3) {
          //   let { value } = node;
          //   console.log(value)
          //   that.getEquipment(value, (list4) => {
          //     console.log(list4)
          //     let arr = list4.map((e) => ({ value: e.id, label: e.name }));
          //     resolve(arr);
          //   })
          // }
          // if (level == 1) {
          //   let { value } = node;
          //   console.log(value)
          //   that.getSystem(value).then(list2=>{
          //     console.log(list2)
          //     let arr = list2.map((e) => ({ value: e.id, label: e.name }));
          //     resolve(arr);
          //   });
          // }
          // if (level == 2) {
          //   let { value } = node;
          //   that.getArea({ cId: value }).then(list3=>{
          //     let arr = list3.map((e) => ({ value: e.aid, label: e.aname,leaf:true }));
          //     resolve(arr);
          //   });
          // }
        },
      },
    };
  },
  mounted() {
    this.addForm.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.addForm.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    this.initData();
  },
  methods: {
    init() {
      this.addSubEquipmentDialog = true
    },
    closeDialog() {
      this.addSubEquipmentDialog = false;
      this.$refs["addForm"].resetFields();
    },
    async getAllCoal(callback) {
      this.getRequest('/dataList/getAllCoal/').then(resp=>{
        if (resp.data){
          callback(resp.data);
          console.log(resp.data)
        }
      });
    },
    async getSystem(coalId, callback) {
      this.getRequest('/coalManage/getSystemInfoByCoalId?coalId=' + coalId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getSubSystem(systemId, callback) {
      this.getRequest('/coalManage/getSubSystemInfoBySystemId?systemId=' + systemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getEquipment(subSystemId, callback) {
      this.getRequest('/coalManage/getEquipmentInfoBySubSystemId?subSystemId=' + subSystemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    addSubEquipment() {
      this.$confirm(`确定进行创建[${this.addForm.subEquipmentName}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postRequest("/subEquipmentManage/insertSubEquipmentInfoByEquipmentId", this.addForm).then((resp) => {
          if (resp.data) {
            this.$emit('update', true)
            this.addSubEquipmentDialog = false
            this.activeIndex = "0"
            this.$refs.addForm.resetFields()
          }
        })
      }).catch(() => {})
    },
    submitForm() {
      if(this.addForm.subEquipmentName === '' || this.addForm.remark === ''){
        this.$message("请完善附属设备信息，附属设备名称和描述不能为空！！！")
      }
      else{
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          console.log(this.addForm)
          this.addForm.equipmentId = this.global.equipment_id
          // this.addForm.equipmentId = this.$refs["cascade"].getCheckedNodes()[0].value
          this.addSubEquipment()
          console.log(this.addForm)
        } else {
          return false;
        }
      })}
    },
  }
}

</script>

<style scoped>

</style>
